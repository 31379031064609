import React from "react";

class BlogTitle extends React.Component {

    render() {
        return (<div className="blogTitle">
            <svg width="379" height="124" viewBox="0 0 379 124" id={this.props.id} class="blogSvg" style={{}} data-sal="slide-right" data-sal-delay="300" data-sal-easing="ease" className={this.props.className} fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M60.344 60.304C63.992 60.88 67.304 62.368 70.28 64.768C73.352 67.168 75.752 70.144 77.48 73.696C79.304 77.248 80.216 81.04 80.216 85.072C80.216 90.16 78.92 94.768 76.328 98.896C73.736 102.928 69.944 106.144 64.952 108.544C60.056 110.848 54.248 112 47.528 112H10.088V11.632H46.088C52.904 11.632 58.712 12.784 63.512 15.088C68.312 17.296 71.912 20.32 74.312 24.16C76.712 28 77.912 32.32 77.912 37.12C77.912 43.072 76.28 48.016 73.016 51.952C69.848 55.792 65.624 58.576 60.344 60.304ZM23.192 54.976H45.224C51.368 54.976 56.12 53.536 59.48 50.656C62.84 47.776 64.52 43.792 64.52 38.704C64.52 33.616 62.84 29.632 59.48 26.752C56.12 23.872 51.272 22.432 44.936 22.432H23.192V54.976ZM46.376 101.2C52.904 101.2 57.992 99.664 61.64 96.592C65.288 93.52 67.112 89.248 67.112 83.776C67.112 78.208 65.192 73.84 61.352 70.672C57.512 67.408 52.376 65.776 45.944 65.776H23.192V101.2H46.376Z" stroke="white" strokeWidth="5" />
                <path d="M111.505 101.344H146.641V112H98.4005V11.632H111.505V101.344Z" stroke="white" strokeWidth="5" />
                <path d="M206.061 113.008C196.749 113.008 188.253 110.848 180.573 106.528C172.893 102.112 166.797 96.016 162.285 88.24C157.869 80.368 155.661 71.536 155.661 61.744C155.661 51.952 157.869 43.168 162.285 35.392C166.797 27.52 172.893 21.424 180.573 17.104C188.253 12.688 196.749 10.48 206.061 10.48C215.469 10.48 224.013 12.688 231.693 17.104C239.373 21.424 245.421 27.472 249.837 35.248C254.253 43.024 256.461 51.856 256.461 61.744C256.461 71.632 254.253 80.464 249.837 88.24C245.421 96.016 239.373 102.112 231.693 106.528C224.013 110.848 215.469 113.008 206.061 113.008ZM206.061 101.632C213.069 101.632 219.357 100 224.925 96.736C230.589 93.472 235.005 88.816 238.173 82.768C241.437 76.72 243.069 69.712 243.069 61.744C243.069 53.68 241.437 46.672 238.173 40.72C235.005 34.672 230.637 30.016 225.069 26.752C219.501 23.488 213.165 21.856 206.061 21.856C198.957 21.856 192.621 23.488 187.053 26.752C181.485 30.016 177.069 34.672 173.805 40.72C170.637 46.672 169.053 53.68 169.053 61.744C169.053 69.712 170.637 76.72 173.805 82.768C177.069 88.816 181.485 93.472 187.053 96.736C192.717 100 199.053 101.632 206.061 101.632Z" stroke="white" strokeWidth="5" />
                <path d="M349.792 40.72C347.008 34.864 342.976 30.352 337.696 27.184C332.416 23.92 326.272 22.288 319.264 22.288C312.256 22.288 305.92 23.92 300.256 27.184C294.688 30.352 290.272 34.96 287.008 41.008C283.84 46.96 282.256 53.872 282.256 61.744C282.256 69.616 283.84 76.528 287.008 82.48C290.272 88.432 294.688 93.04 300.256 96.304C305.92 99.472 312.256 101.056 319.264 101.056C329.056 101.056 337.12 98.128 343.456 92.272C349.792 86.416 353.488 78.496 354.544 68.512H314.512V57.856H368.512V67.936C367.744 76.192 365.152 83.776 360.736 90.688C356.32 97.504 350.512 102.928 343.312 106.96C336.112 110.896 328.096 112.864 319.264 112.864C309.952 112.864 301.456 110.704 293.776 106.384C286.096 101.968 280 95.872 275.488 88.096C271.072 80.32 268.864 71.536 268.864 61.744C268.864 51.952 271.072 43.168 275.488 35.392C280 27.52 286.096 21.424 293.776 17.104C301.456 12.688 309.952 10.48 319.264 10.48C329.92 10.48 339.328 13.12 347.488 18.4C355.744 23.68 361.744 31.12 365.488 40.72H349.792Z" stroke="white" strokeWidth="5" />
            </svg>
        </div>);
    }
}

export default BlogTitle;